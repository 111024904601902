.container {
  height: 100vh;
  display: grid;
  place-items: center;
}

.not-found {
  max-width: 300px;
  padding: 24px;
  text-align: center;
  margin: -100px auto 0;
}
