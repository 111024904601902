.btn {
  position: fixed;
  bottom: 36px;
  right: 36px;
  z-index: 999;
}

.mention-container {
  display: grid;
  grid-template-columns: 30px auto;
  padding: 8px;
  gap: 8px;
  text-indent: 0px;
}

.mention-avatar {
  aspect-ratio: 1;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #bfbfbf;
  font-weight: 500;
}

.mention-content {
  padding: 2px 0;
}

.mention-name {
  margin: 0;
  line-height: 1;
  font-size: 14px;
}

.mention-email {
  margin: 0;
  line-height: 1;
  font-size: 12px;
}
