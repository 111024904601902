.heading {
  margin: 0;
  font-weight: 500;
  line-height: 1;
}

.leading {
  margin: 0;
  line-height: 1;
}

.header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}
