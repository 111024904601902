@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --primary-color: #066fd1;
  --primary-color1: #388cda;
  --primary-color2: #9bc5ed;

  --secondary-color: #6c7a91;
  --secondary-color1: #8995a7;
  --secondary-color2: #c4cad3;

  --success-color: #2fb344;
  --success-color1: #59c269;
  --success-color2: #ace1b4;

  --warning-color: #f76707;
  --warning-color1: #f98539;
  --warning-color2: #fcc29c;

  --danger-color: #d63939;
  --danger-color1: #de6161;
  --danger-color2: #efb0b0;

  --info-color: #4299e1;
  --info-color1: #4299e1;
  --info-color2: #b3d6f3;

  --dark-color: #182433;
  --dark-color1: #46505c;
  --dark-color2: #a3a7ad;

  --light-color: #f6f8fb;
  --light-color1: #cacdd3;
  --light-color2: #717983;

  --table-header-color: #f8f9fa;
  --table-border-color: #ddd;
  --border-color: #0420451a;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background-color: var(--light-color);
}

/* ScrollBar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #ddd;
}

::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Global */
a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

input:focus,
button:focus,
select:focus {
  outline: none;
}

/* Utilities */

.container {
  padding: 0 16px;
  max-width: 1600px;
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.z-10 {
  z-index: 10;
}

.z-999 {
  z-index: 999;
}

.d-grid {
  display: grid;
}

.d-grid-center {
  display: grid;
  place-items: center;
}

.grid-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-span-2 {
  grid-column: span 2 / span 2;
}

.grid-span-3 {
  grid-column: span 3 / span 3;
}

.grid-span-4 {
  grid-column: span 4 / span 4;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.flex-column {
  flex-direction: column;
}

.flex-auto {
  flex: 1 1 auto;
}

.basis-10 {
  flex-basis: 40px;
}

.noshrink {
  flex-shrink: 0;
}

.nogrow {
  flex-grow: 0;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.gap-1 {
  gap: 4px;
}

.gap-2 {
  gap: 8px;
}

.gap-4 {
  gap: 16px;
}

.gap-6 {
  gap: 24px;
}

.gap-8 {
  gap: 32px;
}

.heading {
  font-size: 16px;
  font-weight: 500;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.card {
  border: 1px solid var(--border-color);
  width: 100%;
}

.dash-card-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 24px;
  margin-bottom: 24px;
}

@media screen and (max-width: 1200px) {
  .dash-card-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 564px) {
  .dash-card-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.dash-table {
  width: 100%;
  border: none;
  border-collapse: collapse;
  font-size: 13px;
}

.dash-table thead {
  position: sticky;
  box-shadow: 0px 1px 1px var(--table-border-color);
  top: 0;
}

.dash-table thead td {
  font-weight: 500;
  border-collapse: collapse;
  padding: 10px 16px;
}

.dash-table tbody td {
  border-bottom: 1px solid var(--table-border-color);
  border-collapse: collapse;
  padding: 10px 16px;
}

.table {
  width: 100%;
  border: 1px solid var(--table-border-color);
  border-collapse: collapse;
}

.table thead tr {
  font-weight: 700;
}

.table td {
  border: 1px solid var(--table-border-color);
  border-collapse: collapse;
  padding: 10px 16px;
}

.select {
  border: 1px solid var(--border-color);
  font-size: 14px;
  line-height: 1;
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.input:focus,
.select:focus {
  border: 1px solid var(--primary-color);
}

.input {
  border: 1px solid var(--border-color);
  font-size: 14px;
  line-height: 1;
}

.button {
  cursor: pointer;
  border: none;
}

.button.bg-primary:hover {
  background-color: var(--primary-color1);
  border-color: var(--primary-color1);
}

.button.bg-primary:disabled {
  background-color: var(--primary-color2);
  border-color: var(--primary-color2);
}

.button.bg-secondary:hover {
  background-color: var(--secondary-color1);
  border-color: var(--secondary-color1);
}

.button.bg-secondary:disabled {
  background-color: var(--secondary-color2);
  border-color: var(--secondary-color2);
}

.button.bg-success:hover {
  background-color: var(--success-color1);
  border-color: var(--success-color1);
}

.button.bg-success:disabled {
  background-color: var(--success-color2);
  border-color: var(--success-color2);
}

.button.bg-warning:hover {
  background-color: var(--warning-color1);
  border-color: var(--warning-color1);
}

.button.bg-warning:disabled {
  background-color: var(--warning-color2);
  border-color: var(--warning-color2);
}

.button.bg-danger:hover {
  background-color: var(--danger-color1);
  border-color: var(--danger-color1);
}

.button.bg-danger:disabled {
  background-color: var(--danger-color2);
  border-color: var(--danger-color2);
}

.button.bg-info:hover {
  background-color: var(--info-color1);
  border-color: var(--info-color1);
}

.button.bg-info:disabled {
  background-color: var(--info-color2);
  border-color: var(--info-color2);
}

.button.bg-dark:hover {
  background-color: var(--dark-color1);
  border-color: var(--dark-color1);
}

.button.bg-dark:disabled {
  background-color: var(--dark-color2);
  border-color: var(--dark-color2);
}

.button.bg-light:hover {
  background-color: var(--light-color1);
  border-color: var(--light-color1);
}

.button.bg-light:disabled {
  background-color: var(--light-color2);
  border-color: var(--light-color2);
}

.icon {
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
  color: #fff;
  border-radius: 3px;
}

.progress-bar {
  height: 6px;
  background-color: var(--info-color2);
  position: relative;
}

.progress {
  position: absolute;
  z-index: 2;
  background-color: var(--primary-color);
  top: 0;
  left: 0;
  bottom: 0;
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-success {
  background-color: var(--success-color);
}

.bg-warning {
  background-color: var(--warning-color);
}

.bg-danger {
  background-color: var(--danger-color);
}

.bg-info {
  background-color: var(--info-color);
}

.bg-dark {
  background-color: var(--dark-color);
}

.bg-light {
  background-color: var(--light-color);
}

.bg-table-header {
  background-color: var(--table-header-color);
}

.bg-black {
  background-color: #000;
}

.bg-white {
  background-color: #fff;
}

.bg-transparent {
  background-color: transparent;
}

.border {
  border: 1px solid var(--border-color);
}

.border-top {
  border-top: 1px solid var(--border-color);
}

.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.border-primary {
  border: 1px solid var(--primary-color);
}

.border-secondary {
  border: 1px solid var(--secondary-color);
}

.border-success {
  border: 1px solid var(--success-color);
}

.border-warning {
  border: 1px solid var(--warning-color);
}

.border-danger {
  border: 1px solid var(--danger-color);
}

.border-info {
  border: 1px solid var(--info-color);
}

.border-dark {
  border: 1px solid var(--dark-color);
}

.border-light {
  border: 1px solid var(--light-color);
}

.border-black {
  border: 1px solid #000;
}

.border-white {
  border: 1px solid #fff;
}

.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.text-success {
  color: var(--success-color);
}

.text-warning {
  color: var(--warning-color);
}

.text-danger {
  color: var(--danger-color);
}

.text-info {
  color: var(--info-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-light {
  color: var(--light-color);
}

.text-black {
  color: #000;
}

.text-white {
  color: #fff;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: 8px;
}

.p-4 {
  padding: 16px;
}

.p-6 {
  padding: 24px;
}

.p-10 {
  padding: 40px;
}

.pl-2 {
  padding-left: 8px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\! {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 4px;
  padding-right: 4px;
}

.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.px-6 {
  padding-left: 24px;
  padding-right: 24px;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.pt-2 {
  padding-top: 8px;
}

.pt-5 {
  padding-top: 20px;
}

.pt-6 {
  padding-top: 24px;
}

.pb-4 {
  padding-bottom: 16px;
}

.pb-8 {
  padding-bottom: 32px;
}

.m-0 {
  margin: 0;
}

.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-6 {
  margin-bottom: 24px;
}

.mb-8 {
  margin-bottom: 32px;
}

.round-4 {
  border-radius: 4px;
}

.round-6 {
  border-radius: 6px;
}

.round-t-4 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.font-13 {
  font-size: 13px;
}

.font-12 {
  font-size: 12px;
}

.font-11 {
  font-size: 11px;
}

.font-10 {
  font-size: 10px;
}

.w-8 {
  width: 32px !important;
}

.w-9 {
  width: 36px !important;
}

.w-10 {
  width: 40px !important;
}

.w-full {
  width: 100%;
}

.h-5 {
  height: 20px !important;
}

.h-8 {
  height: 32px !important;
}

.h-9 {
  height: 36px !important;
}

.h-10 {
  height: 40px !important;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.inset-0 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.left-2 {
  left: 8px;
}

.right-2 {
  right: 8px;
}

.top-2 {
  top: 8px;
}

.bottom-2 {
  bottom: 8px;
}

.left-4 {
  left: 16px;
}

.right-4 {
  right: 16px;
}

.top-4 {
  top: 16px;
}

.bottom-4 {
  bottom: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.text-nowrap {
  text-wrap: nowrap;
}

/* Custom */

.e-swimlane-header {
  display: flex;
  align-items: center;
  gap: 4px;
}

.e-swimlane-header .e-icons {
  width: 16px !important;
  height: 16px !important;
}

.e-toolbar {
  background-color: #fff;
}

.e-toolbar .e-toolbar-items {
  background-color: #fff;
}

.e-toolbar .e-toolbar-item .e-tbar-btn {
  background-color: #fff;
}

.e-fe-overlay {
  display: none !important;
}

.e-empty-inner-content {
  display: none !important;
}

.e-dlg-container.e-fe-popup.e-dlg-center-center {
  display: none !important;
  visibility: hidden !important;
  z-index: -999 !important;
}

.e-headercell.e-sort-icon {
  padding: 8px !important;
}

td.e-rowdragdrop.e-rowdragdropcell + td.e-rowcell {
  border-left: 1px solid #ddd;
}

.e-spinner-pane.e-spin-show {
  display: none !important;
}

button.e-tbar-btn.e-btn#save_changes {
  background-color: var(--primary-color);
}

button.e-tbar-btn.e-btn#save_changes > .e-btn-icon.e-icons.e-icon-left {
  color: #fff !important;
}

button.e-tbar-btn.e-btn#save_changes > .e-tbar-btn-text {
  color: #fff !important;
}

button.e-tbar-btn.e-btn#save_changes:hover {
  background-color: var(--primary-color1);
  color: #fff;
}

button.e-tbar-btn.e-btn#reset_changes {
  background-color: var(--danger-color);
}

button.e-tbar-btn.e-btn#reset_changes > .e-btn-icon.e-icons.e-icon-left {
  color: #fff !important;
}

button.e-tbar-btn.e-btn#reset_changes > .e-tbar-btn-text {
  color: #fff !important;
}

button.e-tbar-btn.e-btn#reset_changes:hover {
  background-color: var(--danger-color1);
  color: #fff;
}

.logo {
  font-size: 32px;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 16px;
  text-shadow: 1px 1px 3px #ccc;
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--dark-color);
  padding: 8px 12px 8px 0;
  text-wrap: nowrap;
  height: 28px !important;
}

.nav-item:hover {
  color: var(--primary-color);
}

.tab-item {
  display: block;
  padding: 12px 16px;
  border-bottom: 3px solid transparent;
}

.tab-item.active {
  border-bottom: 3px solid var(--primary-color);
}

.tag {
  padding: 4px 8px;
  border-radius: 100px;
  background-color: var(--light-color1);
  height: 20px;
  display: grid;
  place-items: center;
}

.resource {
  padding: 4px 0px;
  border-radius: 100px;
  background-color: var(--light-color1);
  height: 20px;
  display: inline-grid;
  place-items: center;
  margin-right: 4px;
  font-size: 12px;
}

.kanban-header {
  display: flex;
  align-items: center;
  gap: 4px;
}

.kanban-item {
  font-size: 14px;
  line-height: 1;
  margin: 0;
}

.loader {
  width: fit-content;
  font-size: 40px;
  font-family: system-ui, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px var(--dark-color);
  background: linear-gradient(
      -60deg,
      transparent 45%,
      var(--dark-color) 0 55%,
      transparent 0
    )
    0/300% 100% no-repeat text;
  animation: l3 2s linear infinite;
}
.loader:before {
  content: "Loading";
}
@keyframes l3 {
  0% {
    background-position: 100%;
  }
}
