.navbar {
  background-color: #fff;
  border-bottom: 2px solid var(--border-color);
  overflow: auto;
}

.navbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.navbar::-webkit-scrollbar-track {
  background: #fff;
}

.navbar::-webkit-scrollbar-thumb {
  background: #bbb;
}

.navbar::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
