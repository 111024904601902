.container {
  width: 360px;
  padding: 4px 12px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.body {
  height: 240px;
  padding: 0 8px 0 0;
  overflow: auto;
}

.heading {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.leading {
  margin: 0;
}

.main {
  width: 100%;
}

.notification {
  background-color: #f5f5f5;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.notification:hover {
  background-color: #eee;
}

.timestamp {
  font-size: 12px;
  color: #aaa;
  margin: 0;
  text-align: right;
}
