.tabs {
  display: flex;
  background-color: var(--table-header-color);
  border: 1px solid var(--border-color);
  width: 100%;
  text-wrap: nowrap;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.tabs::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.tabs::-webkit-scrollbar-track {
  background: #fff;
}

.tabs::-webkit-scrollbar-thumb {
  background: #bbb;
}

.tabs::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
